import { Heading } from '@pgl-apps/shared/components'

import { CtaPrimary } from '../../../../buttons'
import { CancelBtn } from '../../Claim/private/CancelBtn'

interface Props {
  onCloseModal: (arg?: unknown) => void
}

export const AcquireToken = (props: Props) => {
  // --------------------- ===
  //  PROPS
  // ---------------------
  const { onCloseModal } = props

  // --------------------- ===
  //  RENDER
  // ---------------------

  return (
    <div>
      <div className="my-12">
        <Heading type="page" tag="h2">
          This Staking Portal Has Moved
        </Heading>
        <div className="my-24 text-theme-muted">
          Stake now on the new KAP Staking portal to earn partner rewards and
          more.
        </div>

        <div className="flex justify-center items-center">
          <div className="w-full max-w-[14rem]">
            <a
              href="https://kap.gg/staking"
              target="_self"
              rel="noreferrer"
              className="font-bold underline"
            >
              {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
              <CtaPrimary label="Go to portal" onClick={() => {}} />
            </a>
          </div>
        </div>
      </div>

      <div className="flex justify-end items-center gap-4 mt-16">
        <CancelBtn onClick={onCloseModal}>Cancel</CancelBtn>
        <div className="w-full max-w-[14rem]">
          <a
            href="https://app.uniswap.org/#/add/v2/0x9625ce7753ace1fa1865a47aae2c5c2ce4418569/ETH"
            target="_blank"
            rel="noreferrer"
            className="font-bold underline"
          >
            {/* eslint-disable-next-line @typescript-eslint/no-empty-function */}
            <CtaPrimary label="Get KAP/ETH LP" onClick={() => {}} />
          </a>
        </div>
      </div>
    </div>
  )
}
